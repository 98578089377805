import { ParamNode } from "@/models/sd/comfy";
import { Form, Input, FormInstance, Switch, Select, Button, Image, Badge, message, Radio } from "antd";
import InputSlider from "@/components/InputSlider";
import CommonUploader from "@/components/CommonUploader";
import FileUploader from "@/components/CommonUploader/FileUploader";
import { WEIGHT_KEYS } from "@/constants/ComfyUI";
import { RandomIcon } from '@/components/Icon/RandomIcon';
import { LoadingOutlined, InboxOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import form from "antd/es/form";
import { t, use } from "i18next";
import workflow from "../..";
import { FieldData, WorkflowItem } from "@/constants";
import { Profile } from "@/models/common/user";
import { useCallback, useEffect, useState } from "react";
import { SdTaskResult } from "@/models/sd/SdFile";
import SignAgreementDialog from "@/components/SignAgreementDialog";
import { useGlobalStore } from "@/store";
import PaymentModal from "@/components/PaymentDialog";

export interface IParamRenderProps {
  param?: Record<string, ParamNode | any>,
  form: FormInstance,
}


const UploadButton = (loading) => {
  return (
  <button style={{ border: 0, background: 'none', height: 222 }} type="button">
    {loading 
      ? <LoadingOutlined /> 
      : <>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag image to this area to upload</p>
      </>
    }
  </button>
)};

const FilePreviewComponent = (value, clear) => {
  if (value?.endsWith?.('!medium')) {
    value = value.replace?.('!medium', '');
  }
  return <div className={styles.imgWrapper}>
    <Input value={value} style={{height: 62, visibility: 'hidden'}} />
    <a href={value} target="_blank" className="absolute flex justify-center items-center w-full h-full">
      {/* 预览按钮 */}
      <Button type="link">点击查看</Button>
    </a>
    <Button className={styles.closeBtn} onClick={clear} type='text'><CloseOutlined /></Button>
  </div>
}

const PreviewComponent = (value, clear) => {
  const isPdf = value?.includes?.('.pdf');
  return <div className={styles.imgWrapper}>
    {
      isPdf ? <div style={{ height: 252 }} className="flex justify-center items-center">
        PDF 文件
      </div>: <Image height={252} style={{objectFit: 'contain'}} src={value} /> 
    }
    <Button className={styles.closeBtn} onClick={clear} type='text'><CloseOutlined /></Button>
  </div>
}

const SeedInput = (props) => {
  const { value, ...otherProps } = props;
  return <div className={styles.SeedInput}>
    <Input value={value} {...otherProps} />
    <Button onClick={() => {
      otherProps?.onChange?.(Math.floor(Math.random() * 100000000000000));
    }}><RandomIcon /></Button>
  </div>
};

const ParamRender = (props: IParamRenderProps) => {
  const { param, form } = props;
  
  if (!param) return null;

  return <>
      {Object.keys(param).filter((key) => {
        const node = param[key];
        return node.paramType !== 'TODO' && node.hide !== 'Y';
      }).map((key) => {
        const node = param[key];
        let compInstance = <Input  />;
        let imageStyle = ''
        if (typeof node.paramConfig?.imageStyle === 'string') {
          imageStyle = node.paramConfig?.imageStyle;
        } else {
          imageStyle = '!medium';
        }
        const accept = node.paramConfig?.accept || '.jpg,.png,.heic,.webp,.gif,.jpeg';
        if (node.advancedConfig?.Component?.ComponentName) {
          const { ComponentName, props, type } = node.advancedConfig?.Component;
          switch (ComponentName) {
            case 'Select':
              if (type === 'Radio') {
                compInstance = <Radio.Group
                  block
                  optionType="button"
                  options={props?.options?.map((item) => {
                    return item;
                  })}
                  defaultValue={props?.defaultValue || node.defaultValue}
                />;
              } else {
                compInstance = <Select
                  options={props?.options?.map((item) => {
                    return item;
                  })}
                  defaultValue={props?.defaultValue || node.defaultValue}
                />;
              }
              break;
            case 'FileUploader':
              compInstance = <FileUploader
                accept={props?.accept || accept}
                previewComponent={FilePreviewComponent}
                uploadButton={UploadButton}
              />;
              break;
            case 'Switch':
              compInstance = <Switch />;
              break;
            case 'Input':
              compInstance = <Input />;
              break;
          }
        } else if (node.Component) {
          switch (node.Component) {
            case 'Input':
              compInstance = <Input />;
              break;
            case 'InputSlider':
              compInstance = <InputSlider />;
              break;
            case 'Switch':
              compInstance = <Switch />;
              break;
            case 'Select':
              compInstance = <Select />;
              break;
            case 'ImageUploader':
              compInstance = accept?.indexOf('pdf') !== -1 
                ? <FileUploader
                    accept={accept}
                    previewComponent={FilePreviewComponent}
                    uploadButton={UploadButton}
                  /> 
                : <CommonUploader
                    imageStyle={imageStyle}
                    accept={accept}
                    previewComponent={PreviewComponent}
                    uploadButton={UploadButton}
                  />
              break;
            case 'SeedInput':
              compInstance = <SeedInput form={form} />;
              break;
          }
        } else {
          switch (node.paramType) {
            case 'INT':
            case 'FLOAT':
            case 'Number':
              let min, max, defaultValue;
              if (node.paramConfig?.max === 0) {
                max = 0;
              } else {
                max = node.paramConfig?.max || node?.max || 2048;
              }
              if (node.paramConfig?.min === 0) {
                min = 0;
              } else {
                min = node.paramConfig?.min || node?.min || 512;
              }
              defaultValue = node.paramConfig?.default || node?.defaultValue;
              compInstance = <InputSlider 
                max={max}
                min={min}
                step={node.paramConfig?.step || node?.step}
                defaultValue={defaultValue}
              />;
              break;
            case 'String':
              if (node.paramConfig?.multiple) {
                compInstance = <Input.TextArea allowClear />;
              } else {
                compInstance = <Input allowClear />;
              }
              break;
            case 'Image':
              compInstance = <CommonUploader
                imageStyle="!medium"
                accept={'.jpg,.png,.heic,.webp,.gif,.jpeg'}
                previewComponent={PreviewComponent}
                uploadButton={UploadButton}/>;
              break;
            case 'Boolean':
              compInstance = <Switch
                checked={node.paramConfig?.default || node?.defaultValue}
              />;
              break;
            case 'Array':
              compInstance = <Select
                options={(node.paramConfig?.options || node?.options || []).map(item => {
                  return {
                    label: item,
                    value: item,
                  }
                })}
              />;
              break;
          }
        }
        // 对一些 key 特殊处理
        if (["seed", "noise_seed", "rand_seed"].includes(node.paramKey)) {
          compInstance = <SeedInput form={form} />;
        } else if ((node.paramKey === 'prompt' || node.paramKey === 'text') && node.Component !== 'ImageUploader') {
          compInstance = <Input.TextArea allowClear />;
        } else if (['batch_size', 'amount'].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={node.max || 8}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        } else if (node.paramKey === 'scale_by') {
          compInstance = <InputSlider 
            max={node.max || 4}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step}
          />;
        } else if (node.paramKey === 'steps') {
          compInstance = <InputSlider 
            max={node.max || 40}
            min={node.min || 1}
            step={node.paramConfig?.step || node?.step}
          />;
        } else if (WEIGHT_KEYS.includes(node.paramKey || '')) {
          compInstance = <InputSlider 
            max={node.max || 1}
            min={node.min || 0}
            step={node.paramConfig?.step || node?.step || 0.01}
          />;
        } else if ([ 'top', 'bottom', 'left', 'right' ].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={node.max || 512}
            min={node.min || 0}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        } else if ([
            'width',
            'height',
            'empty_lantent_width',
            'empty_lantent_height',
          ].includes(node.paramKey)) {
          compInstance = <InputSlider 
            max={2048}
            min={375}
            defaultValue={node.paramConfig?.default || node?.defaultValue || 512}
            step={node.paramConfig?.step || node?.step || 1}
          />;
        }  else if (node.paramKey === 'cfg') {
          compInstance = <InputSlider 
            max={node.max || 10}
            min={node.min || 1}
            step={0.01}
            
          />;
        }

        const notRequired = (node.notRequired === 'Y') || (node.paramConfig?.notRequired === 'Y');
        return <div 
          key={key}  
          className={styles.paramItem}>
          <Form.Item 
            key={key} 
            label={node.name || node.paramKey || node.key || key} 
            name={node.key || key}
            tooltip={node.description || node.paramKey}
            rules={[{ required: !notRequired, message: `请输入${node.name || node.paramKey}` }]}
          >
            {compInstance}
          </Form.Item>
        </div>
      })}
  </>
}

export interface IParamContainerProps {
  workflow: WorkflowItem;
  onGenerate: (param: any) => Promise<void>;
  currentWorks?: SdTaskResult;
  code?: string;
  isMobilePortrait?: boolean;
  profileData?: Profile;
  isNoHead?: boolean;
}

const ParamContainer = (props: IParamContainerProps) => {
  const { workflow, onGenerate, currentWorks, code, isMobilePortrait, isNoHead } = props;
  const [form] = Form.useForm();
  const [generating, setGenerating] = useState(false);
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [messageApi, contextHolder] = message.useMessage();
  const isSignAgreementModalOpen = useGlobalStore((state) => state.isSignAgreementModalOpen);
  const hideSignAgreementModal = useGlobalStore((state) => state.hideSignAgreementModal);
  const showPaymentModal = useGlobalStore((state) => state.showPaymentModal);
  const isPaymentModalOpen = useGlobalStore((state) => state.isPaymentModalOpen);
  const hidePaymentModal = useGlobalStore((state) => state.hidePaymentModal);
  const currentUser = useGlobalStore((state) => state.currentUser);
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const showSignAgreementModal = useGlobalStore((state) => state.showSignAgreementModal);
  const [ cost, setCost ] = useState(0);

  useEffect(() => {
    let batchSize = 1;
    if (workflow?.paramTpl) {
      setFormFields(Object.keys(workflow?.paramTpl).filter((key) => {
        const node: any = workflow?.paramTpl?.[key];
        return node?.paramType !== 'TODO';
      }).map((key) => {
        if (key.startsWith('batch_size')) {
          batchSize = workflow?.paramTpl?.[key]?.defaultValue || 1;
        }
        return {
          name: key,
          value: workflow?.paramTpl?.[key]?.defaultValue
        }
      }));
    }
    const calculatedCost = +workflow?.costByRecord + batchSize * (+workflow?.costByCount || 0);
    setCost(calculatedCost);
  }, [workflow]);

  useEffect(() => {
    let batchSize = 1;
    let defaultFields: FieldData[] = [];
    if (workflow?.paramTpl) {
      defaultFields = Object.keys(workflow?.paramTpl).filter((key) => {
        const node: any = workflow?.paramTpl?.[key];
        return node?.paramType !== 'TODO';
      }).map((key) => {
      return {
        name: key,
        value: workflow?.paramTpl?.[key]?.paramConfig?.default || workflow?.paramTpl?.[key]?.defaultValue
        }
      }) || [];
    }
    if (!currentWorks) {
      setFormFields(defaultFields);
    } else {
      const params = currentWorks?.params?.params;
      if (!params) return;
      
      const parsedFieldDataList: FieldData[] = [
        ...defaultFields,
        ...Object.keys(params).map((key) => {
          let value = params[key];
          if (key.startsWith('batch_size')) {
            batchSize = value || 1;
            value = value || 1;
          }
          return {
            name: key,
            value
          }
        })
      ];
      setFormFields(parsedFieldDataList);
    }

    const calculatedCost = +workflow?.costByRecord + batchSize * (+workflow?.costByCount || 0);
    setCost(calculatedCost);
  }, [currentWorks]);

  const handleFieldsChange = (changedFields: FieldData[]) => {
    setFormFields(changedFields);
    for (const field of changedFields) {
      if (field.name[0]?.toString()?.startsWith?.('batch_size')) {
        const batchSize = field.value || 1;
        const calculatedCost = +workflow?.costByRecord + (batchSize * (+workflow?.costByCount || 0));
        setCost(calculatedCost);
      }
    }
  }

  const handleGenerate = async (ignoreSignAgreement?: boolean) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return;
    }
    if (!ignoreSignAgreement && !currentUser?.signAgreementAt) {
      showSignAgreementModal()
      return;
    }
    // 判断 M 币是否足够，不足则弹出支付框
    const balance = +(currentUser?.mcoinAccount?.balance || 0);
    const workflowCost = +(workflow?.costByRecord || workflow?.costByCount);
    if (balance < workflowCost) {
      showPaymentModal();
      return;
    }
    try {
      if (formFields?.length) {
        const updates = formFields.reduce((acc, cur) => { 
          acc[cur.name] = cur.value;
          return acc;
        }, {});
        form.setFieldsValue(updates);
      }
      const res = await form.validateFields();
      console.log('res: ', res);
    } catch (e) {
      messageApi.error('请输入所有必填项');
      return;
    }
    const updates = form.getFieldsValue();
    const param = {
      workflowCode: code,
      params: {
        ...updates
      }
    };
    setGenerating(true);
    await onGenerate?.(param);
    await getUserInfo();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setGenerating(false);
  } 

  return <div className={
    isMobilePortrait ? styles.mobilePortrait : `${isNoHead ? 'min-h-screen h-full' : 'h-[calc(100vh-64px)]'} overflow-y-auto py-4 flex flex-col gap-4`
  }>
    {contextHolder}
    <div className="flex flex-col w-full">
      <span className="text-2xl">{workflow?.name}</span>
      <span>{workflow?.description || 'no description'}</span>
    </div>
    <Form 
      className={isMobilePortrait ? styles.mobilePortrait : `${isNoHead ? 'h-[calc(100vh-166px)]' : 'h-[calc(100vh-200px)]'} overflow-y-auto rounded-md`} 
      layout="vertical" 
      form={form} 
      fields={formFields} 
      onFinish={handleGenerate}
      onFieldsChange={handleFieldsChange}
    >
      <div className="flex flex-col gap-4">
        <ParamRender param={workflow?.paramTpl} form={form} />
      </div>
    </Form>
    <div className="w-full pr-4">
      <Badge.Ribbon
        text={ t('Discount') } 
        color="#fd12c5"
      >
        <Button 
          type="primary" 
          htmlType="submit" 
          size="large"
          className="w-full h-12" 
          loading={generating}
          onClick={handleGenerate?.bind(null, false)}
        >
          { generating ? t('generating') : t('generate') }
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <span style={{ fontSize: 8 }}> {t('workflow.app.cost')} { `${cost}`?.replace?.('.00', '') } {t('workflow.app.MCoin')}</span>
            <span style={{ fontSize: 8 }}>({t('workflow.app.left')} { currentUser?.mcoinAccount?.balance?.replace?.('.00', '') } {t('workflow.app.MCoin')})</span>
          </div>
        </Button>
      </Badge.Ribbon>
    </div>

    <SignAgreementDialog
      onCancel={hideSignAgreementModal}
      open={isSignAgreementModalOpen}
      onOk={handleGenerate.bind(null, true)}
    />

    <PaymentModal
      onCancel={hidePaymentModal}
      open={isPaymentModalOpen}
    />
  </div>
}

export default ParamContainer;
