import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Button, UploadProps, message } from "antd";

import { OssTOken } from "@/models/oss";
import Dragger from "antd/es/upload/Dragger";
import { useGlobalStore } from "@/store";

export interface IFileUploaderProps extends UploadProps {
  value?: string;
  onChange?: (value: string) => void;
  previewComponent?: (value: string, clear: () => void) => React.ReactNode;
  uploadButton?: (loading) => React.ReactNode;
  uploaderStyle?: React.CSSProperties;
}

const MAX_FILE_SIZE_LIMIT = 50 * 1024 * 1024;


const FileUploader = (props: IFileUploaderProps) => {
  const {value, onChange, previewComponent, uploadButton, uploaderStyle, ...uploaderProps} = props;
  const [loading, setLoading] = useState(false);
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const now = moment(new Date());

  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init, currentUser?.publicId]);

  const beforeUpload = async (file) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return false;
    }

    if (file.size > MAX_FILE_SIZE_LIMIT) {
      messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
      return false;
    }
    
    if (!ossToken) {
      console.error('ossToken is not ready');
      return false;
    }
    setLoading(true);
    const expire = Number(ossToken.expire) * 1000;
  
    if (expire < Date.now()) {
      await init();
    }
  
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return file;
  };
  
  const getExtraData: UploadProps['data'] = (file) => {
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return {
      key: file.url,
      OSSAccessKeyId: ossToken?.accessid,
      policy: ossToken?.policy,
      signature: ossToken?.signature,
      success_action_status: 200,
    };
  };

  const handleClear = () => {
    setLoading(false);
    onChange?.('');
  };

  const pureUpload = <Dragger
    name="file"
    action={ossToken?.host}
    beforeUpload={beforeUpload}
    data={getExtraData}
    {...uploaderProps}
    onChange={(file) => {
      console.log('file', file.file.name);
      if (file.file.status === 'done' && file.file.url) {
        onChange?.(`https://ablula.oss-accelerate.aliyuncs.com/${file.file.url}`)
      }
    }}
  >
    <Button type="text">上传文件</Button>
  </Dragger>

  return <div style={{...uploaderStyle}}>
    {contextHolder}
    { value 
        ? previewComponent?.(value, handleClear)
        : pureUpload
    }
  </div>;
}

export default FileUploader;