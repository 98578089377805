import { onDownload } from "@/utils";
import { DownloadOutlined, FullscreenOutlined, DeleteOutlined } from "@ant-design/icons";
import { Spin, Button, Modal, message, Popconfirm } from "antd";

import styles from './index.module.scss';
import { useEffect, useRef, useState, useMemo } from "react";
import { SdTask, SdTaskResult } from "@/models/sd/SdFile";
import { deleteTask, listTasksPaged } from "@/services/Works";
import { TextOutputRender } from "./HtmlOutputRender";
import moment from 'moment';
import "moment/dist/locale/zh-cn";
import { motion, AnimatePresence } from "framer-motion";

import WorksDetail from "../WorksDetail";
import { getSdTask } from "@/services/SdFile";
import { useGlobalStore } from "@/store";

const PulseLoader = () => (
  <div className="flex justify-center items-center w-full h-full">
    <motion.div
      className="relative w-16 h-16"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [1, 0.5, 1],
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    >
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="8"
        />
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#4f46e5" />
            <stop offset="100%" stopColor="#9333ea" />
          </linearGradient>
        </defs>
      </svg>
      <motion.div
        className="absolute inset-0 flex items-center justify-center text-indigo-600 font-semibold"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        加载中
      </motion.div>
    </motion.div>
  </div>
);

const TechLoader = () => (
  <div className="flex justify-center items-center w-full h-full">
    <motion.div
      className="relative w-24 h-24"
      animate={{
        rotate: 360
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        ease: "linear"
      }}
    >
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#8a5df6" />
            <stop offset="100%" stopColor="#a78bfa" />
          </linearGradient>
        </defs>
        <motion.circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="4"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "reverse"
          }}
        />
        <motion.circle
          cx="50"
          cy="50"
          r="35"
          fill="none"
          stroke="#c4b5fd"
          strokeWidth="2"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2.5,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "reverse"
          }}
        />
      </svg>
    </motion.div>
  </div>
);

export interface IResultPreviewProps {
  item?: SdTaskResult;
  onSelect?: (works?: SdTaskResult) => void;
  onFullscreen?: (works?: SdTaskResult) => void;
  onDelete?: () => void;
  selected?: boolean;
}

const ResultPreview = (props: IResultPreviewProps) => {
  const { item, selected, onSelect, onFullscreen, onDelete } = props;
  const [ isSelected, setIsSelected ] = useState(selected);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);
  if (!item) {
    return null;
  }
  const handleDownload = (e) => {
    e.stopPropagation();
    if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
      messageApi.error('文本输出，不支持下载');
    } else if (item.contentType === 'TEXT') {
      messageApi.error('文本输出，不支持下载');
    } else {
      onDownload(
        item?.publicId, 
        `https://ablula.oss-accelerate.aliyuncs.com/${item?.results}`,
        item?.results?.endsWith?.('.webm') || item?.results?.endsWith?.('.mp4') ? 'video' : 'image'
      );
    }
  }
  let content;
  const imagePath = item?.results;
  const audioUrl = item?.audioUrl;
  const videoUrl = item?.videoUrl;
  const styleSuffix = (item?.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  if (videoUrl) {
    content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://ablula.oss-accelerate.aliyuncs.com/${audioUrl}`} controls loop ></audio>;
  } else if (imagePath) {
    if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
      try {
        content = (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <iframe height="100%" width="100%" src={`/static/llm-card/${item.publicId}`} />
            <div 
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'transparent' }}
              onClick={() => onSelect?.(item)}
            />
          </div>
        );
      } catch (e) {
        console.error('parse origin results failed: ', e);
      }
    } else if (item.contentType === 'TEXT') {
      content = <TextOutputRender data={item.results} />;
    } else if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} controls loop />;
    } else {
      content = <img style={{
        objectFit: 'contain',
        height: '100%',
      }} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}${styleSuffix}`} />;
    }
  }

  return (
    <div className={`${isSelected ? styles.selectedPreviewItem : styles.previewItem} relative group`} onClick={() => { onSelect?.(item) }}>
      {contextHolder}
      {content}
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex space-x-2">
        <Button
          type="text"
          className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
          onClick={(e) => {
            e.stopPropagation();
            onFullscreen?.(item);
          }}
        >
          <FullscreenOutlined />
        </Button>
        <Button
          type="text"
          className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(e);
          }}
        >
          <DownloadOutlined />
        </Button>
        {
          onDelete && (
            <Popconfirm
              title="确认删除"
              description="确定要删除这个作品吗？"
              onConfirm={(e) => {
                e?.stopPropagation();
                onDelete?.();
              }}
              okText="确认"
              cancelText="取消"
            >
              <Button
                type="text"
                className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
                onClick={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        }
      </div>
    </div>
  )
}

export interface ITaskLoadingViewProps {
  item?: SdTask;
  selected?: boolean;
  onSelect?: (item?: SdTaskResult) => void;
}

const TaskLoadingView = (props: ITaskLoadingViewProps) => {
  const { item, selected, onSelect } = props;
  const [currentStatus, setCurrentStatus] = useState(item?.stage);
  const [currentProgress, setCurrentProgress] = useState(item?.progress);

  useEffect(() => {
    setCurrentStatus(item?.stage);
    setCurrentProgress(item?.progress);
  }, [item]);

  if (!item) {
    return null;
  }

  return (
    <div 
      onClick={() => {
        const taskResult = {
          params: item.params,
          taskId: item.publicId,
        }
        onSelect?.(taskResult);
      }}
      className={`bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-md p-2 xs:p-3 sm:p-4 flex flex-col items-center justify-center h-full overflow-hidden relative ${selected ? 'border-2 border-blue-500' : ''}`}
    >
      {currentStatus === 'FAILED' ? (
        <div className="text-red-500 font-bold text-xs xs:text-sm sm:text-base">任务失败</div>
      ) : (
        <>
          <div className="relative w-16 h-16 xs:w-20 xs:h-20 sm:w-24 sm:h-24 mb-2 xs:mb-3 sm:mb-4">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-gray-200"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="44"
                cx="50"
                cy="50"
              />
              <circle
                className="text-blue-500"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="44"
                cx="50"
                cy="50"
                strokeDasharray="276.46015351590177"
                strokeDashoffset={276.46015351590177 - (276.46015351590177 * (currentProgress || 0)) / 100}
                style={{
                  transition: 'stroke-dashoffset 0.5s ease-out',
                }}
              />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-blue-600 font-bold text-xs xs:text-sm sm:text-base">
                {Math.round(currentProgress || 0)}%
              </div>
            </div>
          </div>
          <div className="text-gray-700 font-semibold text-xxs xs:text-xs sm:text-sm mb-1 xs:mb-2 sm:mb-3">
            {+(currentProgress || 0) <= 0 ? "准备中..." : "处理中..."}
          </div>
          <div className="w-full px-1 xs:px-2 sm:px-3">
            <div className="bg-gray-200 h-1 xs:h-1.5 sm:h-2 rounded-full overflow-hidden">
              <div 
                className="bg-blue-500 h-full rounded-full transition-all duration-300 ease-out"
                style={{ width: `${Math.min(Math.max(+(currentProgress || 0), 1), 99)}%` }}
              ></div>
            </div>
          </div>
        </>
      )}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-purple-100 opacity-0 animate-pulse-strong"></div>
    </div>
  );
};

export interface IOutputRenderProps {
  code?: string;
  workflow?: any;
  onSelect?: (works?: SdTaskResult) => void;
  latestGenerateTimestamp?: number;
  isMobilePortrait?: boolean;
  isNoHead?: boolean;
}

interface IWorksHistoryProps {
  code?: string;
  workflow?: any;
  isMobilePortrait?: boolean;
  isNoHead?: boolean;
  onSelect?: (works?: SdTaskResult) => void;
  latestGenerateTimestamp?: number;
}

interface IWorksHistoryItemProps {
  workflow?: any;
  item: SdTask;
  onSelect?: (works?: SdTaskResult) => void;
  onFullscreen?: (works?: SdTaskResult) => void;
  selected?: boolean;
  onDelete?: (taskId: string) => Promise<void>;
}

const WorksHistoryItem = (props: IWorksHistoryItemProps) => {
  const { item, onSelect, onFullscreen, selected, onDelete } = props;
  const { results, stage } = item;
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (result: SdTaskResult) => {
    onSelect?.(result);
  }

  const firstResult = results?.[0];
  const hasMultipleResults = results?.length > 1;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const contentView = item?.status === 'FAILED' 
    ? <div
        className={`${selected ? styles.selectedPreviewItem : styles.previewItem} relative group`} onClick={() => { 
          const taskResult = {
            params: item.params,
            taskId: item.publicId,
          }
          onSelect?.(taskResult) 
        }}> 
        生成失败
        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex space-x-2">
          <Popconfirm
            title="确认删除"
            description="确定要删除这个失败的任务吗？"
            onConfirm={(e) => {
              e?.stopPropagation();
              if (item?.publicId) {
                onDelete?.(item.publicId);
              }
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button
              type="text"
              className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
              onClick={(e) => e.stopPropagation()}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div> 
      </div>
    : <>
      {
        item?.stage === "FINISHED" ? <ResultPreview
          item={firstResult} 
          selected={selected}
          onSelect={handleSelect} 
          onFullscreen={() => onFullscreen?.(firstResult)} 
          onDelete={() => {
            if (item?.publicId) {
              onDelete?.(item.publicId);
            }
          }}
        /> : <TaskLoadingView item={item} onSelect={handleSelect} selected={selected} />
      }
      {hasMultipleResults && (
        <button
          className="absolute bottom-3 right-3 bg-white bg-opacity-90 text-gray-800 text-sm font-medium px-3 py-1 rounded-full shadow-md hover:bg-opacity-100 transition-all duration-200 z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'Close' : `+${results.length - 1}`}
        </button>
      )}
    </>

  return (
    <div 
      ref={containerRef}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 overflow-visible group relative"
    >
      <div className="relative aspect-square">
        {contentView}
      </div>
      
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-lg overflow-hidden z-20 mt-2"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            <div className="p-4 overflow-y-auto">
              <div className="grid grid-cols-2 gap-4">
                {results?.slice(1).map?.((result, index) => (
                  <ResultPreview 
                    key={index} 
                    item={result} 
                    onSelect={() => onSelect?.(result)} 
                    onFullscreen={() => onFullscreen?.(result)} 
                  />
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};


const WorksHistory = (props: IWorksHistoryProps) => {
  const { code, workflow, onSelect, latestGenerateTimestamp } = props;
  const [ tasks, setTasks ] = useState<SdTask[]>([]);
  const [ pageNo, setPageNo ] = useState(1);
  const [ hasMore, setHasMore ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ fullscreenWorks, setFullscreenWorks ] = useState<SdTaskResult>();
  const [ worksList, setWorksList ] = useState<SdTaskResult[]>([]);
  const [ selectedResult, setSelectedResult ] = useState<SdTaskResult>();
  const [ unfinishedTaskList, setUnfinishedTaskList ] = useState<SdTask[]>([]);
  const [ refreshFlag, setRefreshFlag ] = useState(0);
  const loadingSet = useRef(new Set<string>());
  const locale = useGlobalStore((state) => state.locale);

  useEffect(() => {
    // Set moment locale based on the global store
    moment.locale(locale === 'zh-CN' ? 'zh-cn' : 'en');
  }, [locale]);

  const updateTaskByPublicId = (task) => {
    if (!task?.publicId || !tasks?.length) {
      return;
    }
    setTasks(prev => prev.map(item => item.publicId === task.publicId ? task : item));
  }

  useEffect(() => {
    setRefreshFlag(prev => prev + 1);
  }, [latestGenerateTimestamp]);

  useEffect(() => {
    async function init() {
      if (!code) {
        setTasks([]);
        return;
      }
      setIsLoading(true);
      const taskListResp = await listTasksPaged({ type: code, pageNo: 1, pageSize: 20 });
      const taskList = taskListResp?.data?.list;
      setTasks(taskList);
      if (taskList?.[0]?.results?.[0]) {
        handleSelect(taskList?.[0]?.results?.[0]);
      } else if (taskList?.[0]?.stage !== 'FINISHED') {
        const taskResult = {  
          params: taskList?.[0]?.params,
          taskId: taskList?.[0]?.publicId,
        }
        handleSelect(taskResult);
      }
      setHasMore(taskList?.length === 20);
      setIsLoading(false);
    }
    init();
  }, [code, refreshFlag]);

  useEffect(() => {
    if (!code) {
      return;
    }
    const loadMore = async () => {
      setIsLoading(true);
      const taskListResp = await listTasksPaged({ type: code, pageNo: pageNo, pageSize: 20 });
      if (!taskListResp?.data?.list?.length) {
        return;
      }
      setTasks(prev => [...prev, ...(taskListResp?.data?.list || [])]);
      setHasMore(taskListResp?.data?.list?.length === 20);
      setIsLoading(false);
    }
    loadMore();
  }, [pageNo, code])

  useEffect(() => {
    const worksList: SdTaskResult[] = [];
    tasks?.forEach?.(task => {
      if (task.results && Array.isArray(task.results)) {
        worksList.push(...(task?.results || []));
      }
      if (task.stage !== 'FINISHED' && !unfinishedTaskList?.find(item => item.publicId === task.publicId)) {
        setUnfinishedTaskList(prev => [...prev, task]);
      }
    });
    setWorksList(worksList);
    setIsLoading(false);
  }, [tasks]);

  useEffect(() => {
    if (!loadingSet?.current) {
      setIsLoading(false);
      return;
    }
    if (!unfinishedTaskList?.length) {
      setIsLoading(false);
      return;
    }
    unfinishedTaskList.forEach(item => {
      if (!item.publicId) {
        return;
      }
      if (loadingSet.current.has(item.publicId)) {
        return;
      }
      loadingSet.current.add(item.publicId);
    })
    const fetchTask = async () => {
      loadingSet.current.forEach(async id => {
        const taskResp = await getSdTask(id);
        if (taskResp?.data?.stage === 'FINISHED') {
          loadingSet.current.delete(id);
          setRefreshFlag(prev => prev + 1);
        } else {
          updateTaskByPublicId(taskResp?.data);
        }
      })
    }
    const handle = setInterval(() => {
      fetchTask();
    }, 3000);
    return () => clearInterval(handle);
  }, [unfinishedTaskList]);

  const handleSelect = (result: SdTaskResult) => {
    setSelectedResult(result);
    onSelect?.(result);
  }

  const handleDelete = async (taskId: string) => {
    console.log('handleDelete: ', taskId);
    const resp = await deleteTask(taskId);
    if (resp?.code === 0) {
      setRefreshFlag(prev => prev + 1);
    }
  }

  const groupedTasks = useMemo(() => {
    const groups = {};
    tasks?.forEach?.(task => {
      const date = moment(task.createdAt);
      const key = date.format('YYYY-MM-DD');
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(task);
    });
    return Object.entries(groups).sort(([a], [b]) => moment(b).diff(moment(a)));
  }, [tasks]);

  const renderDateHeader = (dateString: string) => {
    const date = moment(dateString);
    let displayDate;
    const isZhCN = locale === 'zh-CN';

    if (isZhCN) {
      displayDate = date.calendar(null, {
        sameDay: '[今天]',
        lastDay: '[昨天]',
        lastWeek: 'M月D日',
        sameElse: 'M月D日'
      });
    } else {
      displayDate = date.calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'MMMM D',
        sameElse: 'MMMM D'
      });
    }

    const dayOfWeek = date.format('dddd');

    return (
      <div className="sticky top-0 z-10 py-4 mb-6 bg-white/80 backdrop-blur-md">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-baseline">
            <span className="text-2xl font-semibold text-gray-900">{displayDate}</span>
            <span className="ml-2 text-sm font-medium text-gray-500">{dayOfWeek}</span>
          </div>
          <div className="text-sm font-medium text-gray-400">
            {isZhCN ? date.format('YYYY年') : date.format('YYYY')}
          </div>
        </div>
        <div className="mt-2 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>
      </div>
    );
  };

  return (
    <div className="h-[calc(100vh-100px)] flex flex-auto flex-col gap-6 overflow-y-auto pr-4">
      {groupedTasks.map(([date, dateTasks]) => (
        <div key={date} className="mb-8">
          {renderDateHeader(date)}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {dateTasks?.map?.(item => (
              <WorksHistoryItem
                key={item.publicId}
                workflow={workflow}
                item={item}
                onSelect={handleSelect}
                onFullscreen={setFullscreenWorks}
                selected={selectedResult?.taskId === item.publicId}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      ))}
      {isLoading && <TechLoader />}
      {!isLoading && hasMore && (
        <div className="flex justify-center items-center py-8 mb-16">
          <button
            onClick={() => setPageNo(prev => prev + 1)}
            className="group relative inline-flex items-center justify-center px-6 py-2 text-sm font-medium text-gray-700 bg-white overflow-hidden rounded-full transition-all duration-300 ease-out hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          >
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-gray-100 to-gray-200 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out origin-left"></span>
            <span className="relative z-10 flex items-center">
              <span className="mr-2 group-hover:translate-y-0.5 transition-transform duration-300 ease-out">Load More</span>
              <svg 
                className="w-4 h-4 transition-transform duration-300 ease-out group-hover:translate-y-0.5" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </span>
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-gray-300 to-gray-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out"></span>
          </button>
        </div>
      )}
      <Modal
        className={styles.worksDetailModal}
        open={!!fullscreenWorks}
        footer={null}
        onCancel={() => {
          setFullscreenWorks(undefined);
        }}
        closeIcon={null}
      >
        <WorksDetail 
          onClose={() => {
            setFullscreenWorks(undefined);
          }}
          works={fullscreenWorks} 
          worksList={worksList}
        />
      </Modal>
    </div>
  );
};

const OutputRender = (props: IOutputRenderProps) => {
  const { code, onSelect, isMobilePortrait, latestGenerateTimestamp, workflow } = props;
  const locale = useGlobalStore((state) => state.locale);

  useEffect(() => {
    // Set moment locale based on the global store
    moment.locale(locale === 'zh-CN' ? 'zh-cn' : 'en');
  }, [locale]);
  return <div className={isMobilePortrait ? '' : 'py-4 gap-4 overflow-y-auto flex flex-row'}>
    <WorksHistory latestGenerateTimestamp={latestGenerateTimestamp} workflow={workflow} code={code} onSelect={onSelect} />
  </div>
}

export default OutputRender;
